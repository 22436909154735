@media (max-width: 768px) {
  .navbarStyle {
    height: auto !important;
  }
}
.no-arrow::after {
  display: none !important;
}
.no-arrow::before {
  display: none !important;
}

.dropdown-menu {
  left: 50% !important;
}

@media (min-width: 1200px) {
  .nav-menu {
    margin-right: auto;
    margin-right: auto;
    display: flex;
  }
}

@media (max-width: 576px) {
  .nav-menu {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .nav-menu {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    width: 820px;
  }
}

@media (min-width: 992px) and (max-width: 1036px) {
  .nav-menu {
    position: relative;
    left: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav-res-unlogin {
    position: relative;
    left: 65px !important;
  }
}

@media (max-width: 991.98px) {
  .res-card {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 992px) and (max-width: 1003px) {
  .nav-menu {
    position: relative;
    left: 50px;
  }
}

@media (min-width: 1036px) and (max-width: 1082px) {
  .nav-menu {
    position: relative;
    left: 30px;
  }
}

@media (max-width: 992px) {
  .navLogin {
    height: auto !important;
  }
}

@media (max-width: 806px) {
  .location-btn {
    margin-left: 4px !important;
  }
}

/* styles.css */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  /* Add custom styles here */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content button {
  color: #333;
  padding: 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdown:hover .dropdown-content {
  display: block;
}
@media (max-width: 768px) {
  .custom-dropdown-menu {
    right: 0;
    left: auto !important;
  }
}

@media (min-width: 991.98px) and (max-width: 1024px) {
  .navUnLogin {
    margin-left: 50px;
  }
}

@media (min-width: 1003px) and (max-width: 1082px) {
  .nav-menu {
    position: relative;
    left: 75px;
  }
}
@media (min-width: 1082px) and (max-width: 1153px) {
  .nav-menu {
    position: relative;
    left: 50px;
  }
}

@media (min-width: 991.98px) and (max-width: 1002px) {
  .navbar-nav {
    position: relative;
    left: 40px !important;
  }
  .navLogin {
    /* margin-left: 50px !important; */
  }
  .nav-menu {
    position: relative;
    left: 105px;
  }
}
