#artical-img {
  margin-top: -30px;
}
@media screen and (max-width: 992px) {
  #artical-img {
    margin-top: -30px;
  }
}

.button-slider {
  display: flex;
  align-items: center;
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 80%; /* Adjust the width as needed */
  margin: auto;
  padding: 10px 0; /* Add padding to the container */
}

.arrow-icon {
  cursor: pointer;
  font-size: 24px;
  background: none;
  border: none;
  padding: 10px;
  margin: 0 10px;
}

.slider-button {
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  flex: 0 0 auto;
  width: 20% !important; /* Adjust the width of buttons */
  height: 33.74px; /* Adjust the height of buttons */
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.38px solid rgba(232, 232, 232, 1);
  border-radius: 23px;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #e8e8e8, #e8e8e8),
    linear-gradient(0deg, #f5f5f5, #f5f5f5);
  box-shadow: none;
  overflow: hidden;
}

.button-content p {
  color: black;
  font-weight: bold;
  margin: 0;
}

.button-content.active {
  background: linear-gradient(331.41deg, #d19b6f 6.78%, #f6e5c3 204.87%);
  box-shadow: 0px 3.6861166954040527px 3.6861166954040527px 0px
    rgba(209, 155, 111, 0.22);
}

.button-content.active p {
  color: white;
}

@media (max-width: 777px) {
  .slider-button {
    width: 31.33% !important; /* Adjust the width for smaller screens */
    padding: 0px !important;
  }
  .button-slider {
    gap: 5px;
  }
}

.article-name-mobile-edit {
}
