@media (max-width: 576px) { 
    .section-col {
        flex: 0 0 50%;
        max-width: 50%;
       
    }
    
}
@media (max-width: 576px) { 
    .img-fav-card {      
        max-width: 100% !important;      
    }
    
}

@media (max-width: 576px) { 
    .img-card-scien {      
        width: 172px !important;    
       
    }
    
}