.d-flex-menu a {
  margin-top: 5px;
  padding: 0px 10px;
  color: #7a808a !important ;
  text-decoration: none;
  font-weight: 700;
}
@media (max-width: 768px) {
  .d-flex-menu a {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .d-flex-menu {
    margin-left: -20px;
  }
}

@media (max-width: 576px) {
  .d-flex-menu a {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .bluefooter {
    font-size: 15px;
    color: rgb(255 255 255 / 77%) !important;
  }
}
@media (max-width: 768px) {
  .bluefooter {
    font-size: 15px;
    color: rgb(255 255 255 / 77%) !important;
  }
}
@media (max-width: 576px) {
  .footer-1 {
    margin-top: -5px !important;
    gap: 0px !important;
    /* margin-right: 0px !important; */
  }
}

@media (max-width: 576px) {
  .footer-2 {
    margin-top: -5px !important;
    gap: 0px !important;
    margin-right: 0px !important;
  }
}
@media (max-width: 576px) {
  .all-footer {
    display: flex;
    flex-direction: column;
  }
}
