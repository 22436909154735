.background-image-card {
  background-image: url("../../images/ground-card.png");
  height: 179px;
  background-size: cover;
}

.background-image {
  background-image: url("../../images/Vector\ \(4\).png");
  background-size: cover;
}
h5.card-namee-details {
  color: #042030;
  font-weight: 600;
}
.custom-nav-dropdown .dropdown-toggle::after {
  border-top-color: black;
  margin-right: 5px;

  /* border-right-color: black; */
}
p.card-namee-details {
  color: rgb(130, 130, 130);
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 17px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #audioInfo {
    width: 130%;
  }
}

@media (max-width: 768px) {
  .icons {
    margin-right: 150px;
    margin-top: -105px;
  }
}
@media (max-width: 768px) {
  .text-info-card {
    padding-right: 35px !important;
  }
}
@media (max-width: 768px) {
  .icons-card {
    margin-right: 150px;
  }
}

@media (max-width: 575.98px) {
  #sounds-icons {
    position: relative;
    left: 10px;
  }
  .search-audio {
    width: 100% !important;
    position: relative;
  }
  .img-search {
    position: relative;
    right: 8%;
  }
  #img-audio-sort {
    width: 100%;
  }
}

@media (max-width: 576px) {
  #sounds-icons {
    /* margin-right: -50px; */
  }
}

@media (max-width: 576px) {
  #img-card-audio {
    max-height: 100%;
    width: 150px;
  }
}
@media (max-width: 576px) {
  .audio-section {
    margin-left: 25px;
  }
}

@media (max-width: 576px) {
  .icons-card {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .icons {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .icons-div {
    margin-right: 30px;
  }
}
@media (max-width: 576px) {
  .icon-audio-card {
    font-size: 30px !important;
  }
}
@media (max-width: 576px) {
  .icons-cat-sort {
    margin-right: 20px !important;
  }
}

.card-namee {
  padding: 20px;
}
@media (max-width: 576px) {
  .card-namee {
    /* background: red !important; */
    width: 170px !important;
    padding: 0px;
  }
}
@media (max-width: 576px) {
  .card-namee-details {
    margin-left: 25px !important;
  }
}

@media (max-width: 576px) {
  #sounds-icons {
    margin-right: -20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .icons-div {
    margin-right: 100px;
  }
  .img-res-audio {
    margin-left: 135px;
  }
  .res-h5-name {
    margin-right: -160px !important;
  }
}

@media (min-width: 375px) and (max-width: 380px) {
  #sounds-icons {
  }
  .icon-mob {
    width: 35px !important;
  }
}

@media (min-width: 354px) and (max-width: 377px) {
  #sounds-icons {
  }
  .icon-mob {
    width: 32px !important;
  }
  #img-card-audio {
    width: 132px !important;
  }
}

.search-form {
  width: 43%;
  position: relative;
}

@media (max-width: 576px) {
  .search-form img {
    right: 84%;
  }
}

.button-slider {
  display: flex;
  align-items: center;
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 80%; /* Adjust the width as needed */
  margin: auto;
  padding: 10px 0; /* Add padding to the container */
}

.arrow-icon {
  cursor: pointer;
  font-size: 24px;
  background: none;
  border: none;
  padding: 0px 10px 10px 10px;
  margin: 0 10px;
}

.slider-button {
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  flex: 0 0 auto;
  width: 25%; /* Adjust the width of buttons */
  height: 33.74px; /* Adjust the height of buttons */
  padding: 2px;
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.38px solid rgba(232, 232, 232, 1);
  border-radius: 23px;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #e8e8e8, #e8e8e8),
    linear-gradient(0deg, #f5f5f5, #f5f5f5);
  box-shadow: none;
  overflow: hidden;
}

.button-content p {
  color: black;
  font-weight: bold;
  margin: 0;
}

.button-content.active {
  background: linear-gradient(331.41deg, #d19b6f 6.78%, #f6e5c3 204.87%);
  box-shadow: 0px 3.6861166954040527px 3.6861166954040527px 0px
    rgba(209, 155, 111, 0.22);
}

.button-content.active p {
  color: white;
}

@media (max-width: 777px) {
  .slider-button {
    width: 31.33% !important; /* Adjust the width for smaller screens */
    padding: 0px !important;
  }
  .button-slider {
    gap: 5px;
  }
}
.elder-image-overlay {
  position: absolute;
  width: 39%;
  height: 100%;
  background: linear-gradient(to right, #a7beae 20%, transparent 100%);
  top: 0px;
}
@media screen and (max-width: 575px) {
  .icons-div {
    justify-content: flex-end !important;
  }
  .res-h5-name {
    margin-right: 0px !important;
  }
  .elder-name-mobile-text {
    justify-content: flex-end !important;
  }
  .audio-time-text {
    justify-content: flex-start !important;
  }
}
