@media (max-width: 768px) {
  .responsive-image {
    display: none;
  }
}

@media (max-width: 768px) {
  .nav-responsive {
    margin-top: 15px !important;
  }
  /* .responsive-ground {
    margin-top: 5px !important ;
  } */
  .navbarStyle {
    height: auto !important;
  }
}

@media (max-width: 992px) {
  .responsive-image {
    display: none;
  }
  #quran-img {
    position: relative;
    bottom: 25px;
    left: 150px;
  }
}

@media (max-width: 768px) {
  .responsive-image {
    display: none;
  }
  #quran-img {
    position: relative;
    bottom: 50px;
    left: 100px;
  }
}
@media (max-width: 992px) {
  /* .responsive-ground {
    margin-top: -10px !important;
  } */
}

@media (max-width: 992px) {
  .navbarStyle {
    margin-top: -35px;
    height: auto !important;
  }
  .nav-responsive {
    margin-top: 50px !important;
    height: auto !important;
  }
  #login-home-button {
    position: relative;
    left: 5px;
  }
}

@media (max-width: 575.98px) {
  .responsive-span-home {
    position: relative;
    right: 15px;
    margin-bottom: 10px;
  }
  .responsive-sounds {
    position: relative;
    right: 20px;
    margin-bottom: 15px;
    padding-left: 20px !important;
  }
  #dr-responsive {
    margin-left: 0px !important;
  }
}

.box-Aritcle,
.box-Book,
.box-Audio,
.box-Pic {
  border: 2px solid #f5ecd9;
  border-radius: 15px;
  margin-top: 15px;
}
.box-Aritcle:hover {
  border: 2px solid #9f9fe7;
  background-color: #85eaff21;
  transition: 0.5s;
}
.box-Book:hover {
  border: 2px solid green;
  background-color: #85eaff21;
  transition: 0.5s;
}
.box-Audio:hover {
  border: 2px solid rgb(0, 107, 128);
  background-color: #85eaff21;
  transition: 0.5s;
}
.box-Pic:hover {
  border: 2px solid rgb(75, 130, 120);
  background-color: #85eaff21;
  transition: 0.5s;
}

@media (max-width: 576px) {
  .img-mobile {
    width: 80% !important;
  }
}

@media (max-width: 576px) {
  .google-img {
    width: 100% !important;
  }
}

@media (min-width: 1199.98px) {
  #mobile-responsive {
    width: 350px;
    height: 562px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #mobile-responsive {
    /* width: 280px !important; */
    height: 450px !important;
  }
  #img-mobile-responsive {
    width: 250px;
    height: 200px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  #mobile-responsive {
    width: 220px !important;
    height: 450px !important;
  }
  #img-mobile-responsive {
    width: 180px;
    height: 200px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  #mobile-responsive {
    width: 300px !important;
    height: 450px !important;
  }
  #img-mobile-responsive {
    width: 250px;
    height: 200px;
  }
}
@media (max-width: 575.98px) {
  #mobile-responsive {
    width: 300px !important;
    height: 450px !important;
  }
  #img-mobile-responsive {
    width: 250px;
    height: 200px;
  }
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin: 20px auto;
}
.append-buttons {
  text-align: center;
  margin-top: 20px;
}

.append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}

.swiper-slide .swiper-slide-active {
  margin-left: 0px !important;
}

.modal-content {
  /* background-color: rgb(237, 234, 234) !important; */
  background-color: #cecece !important;
  padding: 0px !important;
}
.btn-close {
  --bs-btn-close-bg: ;
}

@media (max-width: 1200px) {
  .responsive-image-ground {
    /* background-color: red !important; */
    margin-top: -190px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1401px) {
  .responsive-image-ground {
    /* background-color: green !important; */
    margin-top: -260px !important;
  }
}

.rhap_progress-filled {
  background-color: #d89661;
}
.rhap_progress-indicator {
  background-color: #d89661;
  font-size: 15px;
}
.rhap_progress-container {
  direction: ltr; /* Set direction to right-to-left */
}
.rhap_play-pause-button {
  width: 80px !important;
  height: 50px !important;

  /* Add other styles as needed */
}
.rhap_container {
  box-shadow: none;
}

@media (max-width: 992px) {
  #mobile-responsive {
    width: 300px !important;
  }
  #img-mobile-responsive {
    width: 250px !important;
  }
}

.rhap_volume-controls {
  display: none !important;
}
.rhap_main-controls {
  /* margin-left: 30px !important; */
}

@media (min-width: 992px) {
  .rhap_main-controls {
    margin-left: 30px !important;
  }
}
.rhap_progress-section {
  margin-right: 20px !important;
}

@media (min-width: 1200px) {
  .store-imgs {
    margin-top: 7.5% !important;
  }
}
@media (max-width: 600px) {
  .profile-img img {
  }
  .profile-img {
    width: 170px !important;
    height: 170px !important;
  }
}
@media (max-width: 806px) {
  .quran-img {
    position: absolute !important;
    right: 0px !important;
    bottom: -28px !important;
  }
}

/*AMR*/
.quran-img {
  width: 100px;
  position: absolute !important;
  /* bottom: -30px !important; */
  bottom: 0px !important;
  right: 0px !important;
}

@media (min-width: 809px) and (max-width: 990px) {
  .doctor-name {
    font-size: 45.4px !important;
  }
  .pray-time {
    font-size: 20.55px !important;
  }
  .next-appointment {
    font-size: 18.15px !important;
  }
}
@media (min-width: 576px) and (max-width: 807px) {
  .doctor-name {
    font-size: 32.4px !important;
  }
  .pray-time {
    font-size: 17.55px !important;
  }
  .next-appointment {
    font-size: 15.15px !important;
  }
}

.iconify,
.iconify iconify--mdi {
  transform: scaleX(-1) !important;
}
@media (min-width: 992px) {
  .orange-circle {
    width: 30px;
    height: 30px;
    background-color: #d89661;
    border-radius: 50%;
  }
}
@media (min-width: 1200px) {
  .store-images-mobile {
    margin-bottom: 17.5% !important;
  }
}
@media (min-width: 1399px) {
  .store-images-mobile {
    margin-bottom: 16.5% !important;
  }
}

.new-card-books {
  width: 10.666667% !important;
}
@media (max-width: 992px) {
  .new-card-books {
    width: 17.666667% !important;
  }
  .new-card-books img {
    height: 120px !important;
  }
}
/*AMR*/
