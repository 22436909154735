.background-button {
  background-image: url("../../images/groundbutton.png");
  background-size: cover;
  background-size: 100%;
  width: 250px !important;
  border: 2px solid #ffffff;
  margin: 10px;
  background-repeat: no-repeat;
  font-size: 20px;
  background-color: "red";
}

@media (max-width: 768px) {
  .background-button {
    background-image: none;
    background-color: rgba(199, 140, 92, 1);
    font-size: 5px;
    border: 5px solid #d0cccc;
  }
}

.profileButton {
  background-image: url("../../images/groundbutton.png");
  background-repeat: no-repeat;
  font-size: 20px;
  padding: 15px;
  margin: 5px;
  width: 100%;
  border-radius: 25px;
  background-size: cover;
  background-size: 100%;
}

.deleteButton {
  border: 2px solid rgba(229, 25, 55, 0.09);
  background-color: rgba(229, 25, 55, 0.09);
  padding: 15px;
  margin: 20px 5px;
  width: 100%;
  border-radius: 10px;
}
.modal {
  --bs-modal-header-border-width: none !important;
  --bs-modal-header-padding: none !important;
}
.modal-dialog {
  margin: 15px !important;
}

.btn-close {
  --bs-btn-close-bg: url("../../images/Arroww.png") !important;
  --bs-btn-close-bg-width: 50% !important;
  --bs-btn-close-opacity: none !important;
  --bs-btn-close-hover-opacity: none !important;
}
.btn-primary {
  --bs-btn-bg: none !important;
  --bs-btn-hover-bg: none !important;
}
.mod {
  padding: 0px !important;
}
@media (max-width: 576px) {
  .card-mod {
    margin-left: -20px !important;
  }
}

.text-left {
  direction: ltr;
}

.text-right {
  direction: rtl;
}

.activationBtn {
  padding: 0px 15px;
  border: none;
  border-radius: 10px;
  background-color: #d7a77d;
  color: #fff;
}
.react-tel-input {
}
.phone-input {
  display: flex;
}
.phone-input .form-control {
  background-color: rgba(245, 245, 245, 1) !important;
  height: 56px !important;
  border-radius: 8px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spin buttons in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
