.accordion-body {
  background-color: rgba(244, 245, 247, 1) !important;
  color: rgba(209, 155, 111, 1) !important;
}
.accordion-button {
  background-color: rgba(244, 245, 247, 1) !important;
  color: rgba(209, 155, 111, 1) !important;
}
.accordion-item {
  border: none;
}

.accordion-button::after {
  /* Hide the default arrow icon */
  display: none;
}
.accordion-button::before {
  /* Add the @ symbol */
  content: "+";
  /* Add additional styling as needed */
  font-size: 1.25rem;
  color: #000;
  margin-right: 0.5rem;
}
.accordion-button:not(.collapsed)::before {
  content: "-";
  font-size: 1.25rem;
  color: #000;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .search-book {
    /* width: 150px !important; */
  }
  .search-icon {
    position: "relative";
    margin-top: "-30px";
    margin-right: "50px";
    right: 30%;
  }
  /* #box-books{
        width: 80px !important;
    } */
}

@media (max-width: 576px) {
  .search-book {
    width: 100% !important;
  }
  .search-icon {
    position: "relative";
    margin-top: "-30px";
    margin-right: "50px";
    right: 10%;
  }
}

@media (max-width: 768px) {
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 576px) {
  .card-book-sort {
    width: 100% !important;
    padding: 0px !important;
  }
}

@media (max-width: 576px) {
  .card-book-img {
    width: 100% !important;
    height: 270px !important;
  }
}
@media (max-width: 770px) {
  #box-books {
    display: none;
  }
  .card-sort-mobile {
    display: flex;
    justify-content: center;
  }
}
/* ButtonSlider.css */
/* .button-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.arrow {
  cursor: pointer;
  font-size: 24px;
  background: none;
  border: none;
  padding: 10px;
}

.slider-button {
  flex: 0 0 auto;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  width: 124px; 
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.38px solid rgba(232, 232, 232, 1);
  border-radius: 23px;
  width: 100%;
  height: 33.74px;
  background: linear-gradient(0deg, #e8e8e8, #e8e8e8),
    linear-gradient(0deg, #f5f5f5, #f5f5f5);
  box-shadow: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-content.active {
  background: linear-gradient(331.41deg, #d19b6f 6.78%, #f6e5c3 204.87%);
  box-shadow: 0px 3.6861166954040527px 3.6861166954040527px 0px
    rgba(209, 155, 111, 0.22);
}

.button-content p {
  color: black;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 13px;
}

.button-content.active p {
  color: white;
}
 */
/* ButtonSlider.css */
.button-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 80%;
  margin: auto;
}

.arrow-icon {
  cursor: pointer;
  font-size: 24px;
  background: none;
  border: none;
  padding: 0px 10px 10px 20px;
  margin: 0 10px;
}

.slider-button {
  flex: 0 0 auto;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  width: 20%; /* Adjust the width as needed */
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.38px solid rgba(232, 232, 232, 1);
  border-radius: 23px;
  width: 100%;
  height: 33.74px;
  background: linear-gradient(0deg, #e8e8e8, #e8e8e8),
    linear-gradient(0deg, #f5f5f5, #f5f5f5);
  box-shadow: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-content.active {
  background: linear-gradient(331.41deg, #d19b6f 6.78%, #f6e5c3 204.87%);
  box-shadow: 0px 3.6861166954040527px 3.6861166954040527px 0px
    rgba(209, 155, 111, 0.22);
}

.button-content p {
  color: black;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 13px;
}

.button-content.active p {
  color: white;
}
@media (max-width: 777px) {
  .slider-button {
    width: 31.33% !important; /* Adjust the width for smaller screens */
  }
}
