.fade {
  display: flex !important;
  justify-content: center !important;
}
.modal-content {
  background-color: #00000057;
  margin-top: 80px;
}

.show-pic .modal-content {
  /* padding: 15px !important; */
  background-color: transparent !important;
  border: none;
}
.show-pic .modal-footer {
  border-top: none;
}
.show-pic {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.show-pic .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
@media (max-width: 575.98px) {
  #img-responsive-pic {
    width: 250px;
  }
}
.arrow-icon {
  padding: 0px 10px 10px !important;
}
/*Amr*/
@media only screen and (max-width: 640px) {
  .images-container {
    margin-top: -22% !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1370px) {
  .images-container {
    margin-top: -11% !important;
  }
}

@media only screen and (min-width: 1371px) {
  .images-container {
    margin-top: -9% !important;
  }
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* Adjust gutter size */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* Adjust gutter size */
  background-clip: padding-box;
}

/* Style for individual items */
.grid-item {
  margin-bottom: 30px; /* Adjust margin size */
  position: relative;
}
.grid-item img {
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.masonry-item {
  margin-bottom: 30px; /* gutter size */
}

.masonry-img {
  width: 100%;
  display: block;
  border-radius: 8px;
  cursor: pointer;
}

/* Odd columns: first image 150px, second image 350px, etc. */
.my-masonry-grid_column:nth-child(odd)
  > .masonry-item:nth-child(odd)
  .masonry-img {
  height: 250px;
}
.my-masonry-grid_column:nth-child(odd)
  > .masonry-item:nth-child(even)
  .masonry-img {
  height: 350px;
}

/* Even columns: first image 350px, second image 150px, etc. */
.my-masonry-grid_column:nth-child(even)
  > .masonry-item:nth-child(odd)
  .masonry-img {
  height: 350px;
}
.my-masonry-grid_column:nth-child(even)
  > .masonry-item:nth-child(even)
  .masonry-img {
  height: 250px;
}

.images-container {
  padding: 20px;
}

.modal-img {
  width: 350px;
  height: 400px;
  border-radius: 12px;
}

@media (max-width: 500px) {
  .masonry-item {
    margin-bottom: 15px; /* reduce gutter size for small screens */
  }

  .my-masonry-grid_column {
    padding-left: 15px; /* reduce gutter size for small screens */
  }
}
